import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import { hasCookie, setCookie } from '~/utils/cookies';

const firstSeenKey = 'bb_first_seen';
const lastSeenKey = 'bb_last_seen';

const useCollectAnalyticsCookies = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = Object.fromEntries(new URLSearchParams(queryString));
    const utmParams: [string, string][] = [];

    Object.entries(urlParams).map((param) => param[0].includes('utm') && utmParams.push(param));

    // eslint-disable-next-line array-callback-return
    utmParams.forEach((param) => {
      if (document.cookie.indexOf(`${param[0]}=`) === -1) {
        document.cookie = `${param[0]}=${param[1]}; domain=bugbug.io`;
      }
    });

    const isDirectVisit = !window.location.search && !document.referrer;
    const visitData = JSON.stringify({
      date: new Date().toISOString(),
      referrer: isDirectVisit ? 'direct' : document.referrer,
      search: isDirectVisit ? 'direct' : window.location.search,
      on: `${window.location.origin}${window.location.pathname}`,
    });

    const setBackupInLocalStorage = () => {
      if (!localStorage.getItem(firstSeenKey)) {
        localStorage.setItem(firstSeenKey, visitData);
      }
      localStorage.setItem(lastSeenKey, visitData);
    };

    const setCookies = () => {
      if (!hasCookie(firstSeenKey)) {
        setCookie({ name: firstSeenKey, value: localStorage.getItem(firstSeenKey) ?? visitData });
      }
      setCookie({ name: lastSeenKey, value: localStorage.getItem(lastSeenKey) ?? visitData });
    };

    // Try to set data if it's possible
    setBackupInLocalStorage();
    setCookies();

    // Handle consent mode flow
    // https://help.cookie-script.com/en/javascript-code-documentation/custom-events
    const handleAcceptAll = () => {
      setCookies();
      mixpanel.people.set({ cookies_consent_homepage: true });
    };
    const handleReject = () => {
      mixpanel.people.set({ cookies_consent_homepage: false });
    };
    window.addEventListener('CookieScriptAcceptAll', handleAcceptAll);
    window.addEventListener('CookieScriptReject', handleReject);

    return () => {
      window.removeEventListener('CookieScriptAcceptAll', handleAcceptAll);
      window.removeEventListener('CookieScriptReject', handleReject);
    };
  }, []);
};

export default useCollectAnalyticsCookies;
